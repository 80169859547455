@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-thin-straight/css/uicons-thin-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-bold-straight/css/uicons-bold-straight.css');

@import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@import url('https://fonts.cdnfonts.com/css/badelosh-ralintesla');

body {
  margin: 0;
  font-family: 'Badelosh Ralintesla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lato-font {
  font-family: 'Lato', serif;
}

.montserrat-font {
  font-family: 'Montserrat', serif;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.bg-gradient {
  background: rgb(2,2,89);
  background: linear-gradient(90deg, rgba(2,2,89,1) 0%, rgba(0,0,255,1) 100%);
}

.bg-home {
  background-image:linear-gradient(black, black), url('./assets/images/bg_img_home.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-about {
  background-image:linear-gradient(black, black), url('./assets/images/bg_img_about.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-about2 {
  background-image: linear-gradient(black, black), url('./assets/images/img_about2.png');
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-about-mobile {
  background-image: linear-gradient(black, black), url('./assets/images/img_about_mobile.svg');
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-professionals {
  background-image: linear-gradient(black, black), url('./assets/images/bg_img_professionals.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-services {
  background-image: linear-gradient(black, black), url('./assets/images/bg_img_services.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-client {
  background-image: linear-gradient(black, black), url('./assets/images/bg_img_client.png');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-contact {
  background-image: linear-gradient(black, black), url('./assets/images/bg_img_contact.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-update {
  background-image: linear-gradient(black, black), url('./assets/images/bg_img_update.svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-footer {
  background-image: url('./assets/images/bg-footer.jpg');
  background-size: cover;
  background-clip: initial;
}


html{
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"] { 
  caret-color: #B39659; 
} 

.ping {
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}